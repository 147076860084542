import * as React from "react";

import Seo from "../components/seo";

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>404 error: Not Found</h1>
    <p>{"Sorry, this page doesn't exist."}</p>
  </>
);

export default NotFoundPage;
